<template>
  <div class="game-container">
    <RecognitionPinyinBasketBallGame
      :answerList="answerList"
      :optionList="optionList"
      :audioList="audioList"
    />
  </div>
</template>

<script>
import RecognitionPinyinBasketBallGame from "@/components/Course/GamePage/RecognitionPinyinBasketBallGame";
export default {
  data() {
    return {
      lessonId: 7,
      answerList: [
        {
          id: 1,
          sound: "",
          showObj: true,
        },
        {
          id: 4,
          sound: "",
          showObj: true,
        },
        {
          id: 3,
          sound: "",
          showObj: true,
        },
        {
          id: 1,
          sound: "",
          showObj: true,
        },
        {
          id: 2,
          sound: "",
          showObj: true,
        },
        {
          id: 3,
          sound: "",
          showObj: true,
        },
        {
          id: 4,
          sound: "",
          showObj: true,
        },
      ],

      optionList: [],
      audioList:[
        require("@/assets/audio/a-zh/b/bo1.mp3"),
        require("@/assets/audio/a-zh/f/fo1.mp3"),
        require("@/assets/audio/a-zh/m/mo1.mp3"),
        require("@/assets/audio/a-zh/b/bo1.mp3"),
        require("@/assets/audio/a-zh/p/po1.mp3"),
        require("@/assets/audio/a-zh/m/mo1.mp3"),
        require("@/assets/audio/a-zh/f/fo1.mp3"),
      ]
    };
  },
  mounted () {
    let newArr=[]
    for (let i = 0; i < 7; i++) {
      const ele =  [{
            id: 1,
            bottomImg: require("@/assets/img/16-GAMES/G45-basketball/backetball-b.svg"),
            showObj: true,
          },
          {
            id: 2,
            bottomImg: require("@/assets/img/16-GAMES/G45-basketball/backetball-p.svg"),
            showObj: true,
          },
          {
            id: 3,
            bottomImg: require("@/assets/img/16-GAMES/G45-basketball/backetball-m.svg"),
            showObj: true,
          },
          {
            id: 4,
            bottomImg: require("@/assets/img/16-GAMES/G45-basketball/backetball-f.svg"),
            showObj: true,
          },]
          newArr.push(ele)
    }
    //  return newArr
    this.optionList = newArr
    console.log(this.optionList,'qqqqqqqqqqqqq');
   
    
  },
  components: {
    RecognitionPinyinBasketBallGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>