<template>
  <div class="sing-page-container" :class="{ 'layout-border': !isReviewPage }">
    <SingPage :singList="singList" :songInfo="songInfo" :timeStampList="timeStampList" :isReviewPage="isReviewPage"></SingPage>
  </div>
</template>
<script>
import SingPage from "@/components/Course/CoursePage/SingPage";

export default {
  props: {
    isReviewPage: {
      type: Boolean,
    },
  },
  components: {
    SingPage,
  },
  data() {
    return {
      songInfo: {
        songUrl: require("@/assets/audio/songs/lesson-79.wav"),
      },
      timeStampList: [16.6, 22.1, 32.7, 38.7, 48.6, 54.2],
      singList: {
        lyricsArr: [
          {
            id: 1,
            spelling: "Máobǐ ，máobǐ ，máobǐ zì,",
            chineseWords: "毛笔，毛笔，毛笔字 ,",
          },
          {
            id: 2,
            spelling: "wǒ de máobǐ hěn dà,wǒ de máobǐ zì hěn piàoliang.",
            chineseWords: "我的毛笔很大，我的毛笔字很漂亮。",
          },
          {
            id: 3,
            spelling: "Shànzi,shànzi,tiào guǎngchǎngwǔ, ",
            chineseWords: "扇子，扇子，跳广场舞,",
          },
          {
            id: 4,
            spelling: "wǒ de shànzi hěn piàoliang,wǒ xǐhuan tiào guǎngchǎngwǔ.",
            chineseWords: "我的扇子很漂亮，我喜欢跳广场舞。",
          },
          {
            id: 5,
            spelling: "zǎo shuì,zǎo qǐ,zǎo shuì zǎo qǐ,",
            chineseWords: "早睡，早起，早睡早起 ,",
          },
          {
            id: 6,
            spelling: "zǎo shuì zǎo qǐ shēntǐ hǎo.",
            chineseWords: "早睡早起身体好。",
          },
        ],
      },
    };
  },
};
</script>
<style lang="scss" scoped>
.singpage-container {
  width: calc(100% - 20px);
  height: calc(100% - 40px);
}
</style>
