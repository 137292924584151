<template>
  <div class="game-container">
    <ClickImgToShowSentenceGame
      :bgImg="bgImg"
      :optionList="optionList"
      :maskImgList="maskImgList"
      @showCoeerctImg="showCoeerctImg"
      :titleInfo="titleInfo"
      :lessonNO="26"
    />
  </div>
</template>

<script>
import ClickImgToShowSentenceGame from "@/components/Course/GamePage/ClickImgToShowSentenceGame";
export default {
  data() {
    return {
      titleInfo: {
        pinyin: "Kàn! Zhè/Nà shì...",
        hanzi: "看！这/那是……",
        position: "right"
      },
      bgImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-26/background.svg"),
      optionList: [
        {
          bgImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-26/1-found.svg"),
          clickImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-26/1-found.svg"),
          id: 1,
          isShowCorrect: false,
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-26/2-found.svg"),
          clickImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-26/2-found.svg"),
          id: 2,
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-26/3-found.svg"),
          clickImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-26/3-found.svg"),
          id: 3,
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-26/4-found.svg"),
          clickImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-26/4-found.svg"),
          id: 4,
        }
      ],
      maskImgList: [
        {
          img: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-26/image-1.svg"),
          id: 1,
          centencePinyin: "Tā zài xiě máobǐ zì.",
          sentenceHanzi: " 他在写毛笔字。"
        },
        {
          img: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-26/image-2.svg"),
          id: 2,
          centencePinyin: "Tāmen zài dǎ tàijíquán.",
          sentenceHanzi: "他们在打太极拳。",
        },
        {
          img: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-26/image-3.svg"),
          id: 4,
          centencePinyin: "Tā de shànzi zhēn dà！",
          sentenceHanzi: "他的扇子真大！",
        },
        {
          img: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-26/image-4.svg"),
          id: 3,
          centencePinyin: "Tāmen zài tiào guǎngchǎngwǔ.",
          sentenceHanzi: " 她们在跳广场舞",
        }
      ],
    };
  },
  components: {
    ClickImgToShowSentenceGame,
  },
  methods: {
    showCoeerctImg(index) {
      this.optionList[index - 1].isShowCorrect = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>
